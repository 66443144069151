<template>
  <b-sidebar
      id="timesheet-edit-sidebar"
      :visible="isTimesheetEditSideBarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-timesheet-edit-side-bar-active', val)"

  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0" v-if="timesheetData.start">
          Edit timesheet - {{momentFormat(timesheetData.start, 'DD-MM-YYYY')}}
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
      >
        <!-- Form -->
        <div
            class="p-2"
        >
          <div class="alert alert-warning p-1 d-flex font-weight-bold">
            <feather-icon class="text-warning " icon="AlertCircleIcon" size="25"/>
            <label class="ml-1 font-small-4 ">
              All timesheets need to be approved by Practice Admin.
              To simplify this process, please leave commentary when changing start and/or end time.</label>
          </div>
          <b-row>
            <b-col cols="12" md="4">
              <label>Start Time</label>
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="startTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <label>End Time</label>
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="endTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
          <div v-if="startTime !== startTimeBeforeUpdate || endTime !==endTimeBeforeUpdate" class="alert alert-primary p-1 d-flex font-weight-bold">
            <label class="ml-1 font-small-4 ">
              <del> {{timesheetData.time}}</del>
            </label>
            <label class="ml-1 font-small-4 font-weight-bold">
              <strong>New time: {{startTime}} - {{endTime}}</strong> </label>
          </div>
          <b-row>
            <b-col class="mt-1" cols="12">
              <b-form-checkbox
                  v-model="takenBreak"
                  :value="true"
                  class=""
              >
                I have taken my break
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1" cols="12">
              <label>Commentary</label>
              <b-form-textarea
                  id="textarea-default"
                  v-model="commentary"
                  placeholder="Why are you changing the shift time?"
                  rows="3"
              />
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex  mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="isActive"
                type="submit"
                variant="primary"
                @click="updateTimesheet()"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

import TimesheetAPI from '@/apis/modules/timesheet'
import moment from 'moment'

export default {

  components: {
    flatPickr,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver

  },
  directives: {
    Ripple
  },

  model: {
    prop: 'isTimesheetEditSideBarActive',
    event: 'update:is-timesheet-edit-side-bar-active'
  },
  props: {
    isTimesheetEditSideBarActive: {
      type: Boolean
    },
    timesheetData: {
      type: Object
    }
  },
  mixins: [MomentMixin],
  data () {
    return {
      required,
      startTime: '',
      endTime: '',
      commentary: '',
      takenBreak: false,
      alphaNum,
      hide_btn: true,
      noDataTable: '',
      tableLoading: false,
      is_taken_break: false,
      startTimeBeforeUpdate:'',
      endTimeBeforeUpdate:'',
      commentaryBeforeUpdate:'',
      isActive: true,
    }
  },
  watch: {
    async timesheetData (val) {
      await this.setDetails(val)
    },

    async startTime() {
      this.checkTimeSheetDetails()
    },

    async endTime() {
      this.checkTimeSheetDetails()
    },

    async commentary() {
      this.checkTimeSheetDetails()
    },

    async takenBreak() {
      this.checkTimeSheetDetails()
    }
  },
  methods: {
    toggle () {
      this.isTimesheetEditSideBarActive = false
    },

    checkTimeSheetDetails() {
      if (this.startTime !== this.startTimeBeforeUpdate || this.endTime !== this.endTimeBeforeUpdate || this.commentary !== this.commentaryBeforeUpdate)
      {
        return this.isActive = false
      } else {
        return this.isActive = true
      }
    },

    async updateTimesheet () {
      try {
        let payload = {}
        payload.start = moment(this.momentFormat(this.timesheetData.shift.start, 'YYYY-MM-DD') + ` ` + this.startTime).utc().format('YYYY-MM-DD HH:mm:ss')
        payload.end = moment(this.momentFormat(this.timesheetData.shift.end, 'YYYY-MM-DD') + ` ` + this.endTime).utc().format('YYYY-MM-DD HH:mm:ss')
        payload.commentary = this.commentary
        payload.is_taken_break = this.takenBreak
        await TimesheetAPI.updateTimesheet(this.timesheetData.id, payload)
        let startTime = moment(this.momentFormat(this.timesheetData.shift.start, 'YYYY-MM-DD')).format('DD/MM/YYYY')
        this.showSuccessMessage(`Timesheet from ${startTime} was updated!`)
        this.$emit('dataRefresh')
        this.toggle()

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async setDetails (val) {
      this.startTime = this.momentFormat(val.start, 'HH:mm')
      this.startTimeBeforeUpdate = this.momentFormat(val.start, 'HH:mm')
      this.endTime = this.momentFormat(val.end, 'HH:mm')
      this.endTimeBeforeUpdate = this.momentFormat(val.end, 'HH:mm')
      this.commentary = val.commentary
      this.commentaryBeforeUpdate = val.commentary
    }
  }

}
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>

#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
